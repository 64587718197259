<template>
  <div class="construct">
    <base-empty :title="text" class="vps__empty" />
  </div>
</template>

<script>
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
export default {
  name: 'Construct',
  components: {
    BaseEmpty,
  },
  data() {
    return {
      calendarValue: { start: new Date(), end: new Date() },
      periods: ['week', 'month', 'quarter', 'year'],
      text: 'Страница находится в разработке',
    };
  },
  methods: {
    onCalendarChange(val) {
      this.calendarValue = val;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
